import { createStyles } from "@mantine/core";

export const useFifthTemplateStyles = createStyles({
    Contact_heading: {
        borderBottom: "1px solid #d5d6d6",
    },
    skillsheading: {
        paddingBottom: "15px",
        paddingTop: "24px",
      },
})